import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { LoadingOverlay } from '../loading-overlay/loading-overlay';
import { getUserID, signOutFromFirebase } from '../../hooks/useFirebase';
import useAuthentication from '../../hooks/useAuthentication';
import { stepsMessage } from '../../constants';
import { getClaimantData } from '../../services/claims.service';
import { usePaymentMethod, useSnackbar, useClaimStatus } from '../../store';

const query = graphql`
  query RouteGuards($id: StringQueryOperatorInput = {}) {
    template(id: $id) {
      caseID
      authentication {
        loginForm
      }
    }
  }
`;

const SubmissionProtection = ({ children }) => {
  const data = useStaticQuery(query);
  const caseID = data.template?.caseID;
  const { loginForm } = data.template.authentication;
  const uid = getUserID();
  const pathname = useLocation().pathname.replace(/\/$/, '');
  const { authenticated, loadingAuthentication } = useAuthentication();
  const [isDefinedPurchaser, setIsDefinedPurchaser] = useState(false);
  const [loadingDefinedPurchaserState, setLoadingDefinedPurchaserState] =
    useState('idle'); //'idle', 'success', 'pending', 'error'
  const { openSnackbar } = useSnackbar();
  const PROTECTED_ROUTES = [
    '/claim-check',
    '/claim-form',
    '/results',
    '/claim-instructions',
  ];

  const previousClaimStatus = useClaimStatus();
  const previousPaymentInfo = usePaymentMethod();
  const isInProtectedRoute = PROTECTED_ROUTES.includes(
    pathname.replace(/\/$/, '')
  );

  const hasPreviousRejectedClaim =
    previousClaimStatus?.message === stepsMessage.step5_b;
  const hasCompletedPayment = previousPaymentInfo.paymentMethod;

  const hasPreviousSubmissionWithPaymentInfo =
    previousClaimStatus.hasPreviousSubmission && hasCompletedPayment;
  const hasCompletedClaimFlow =
    hasPreviousRejectedClaim || hasPreviousSubmissionWithPaymentInfo;
  const caseRequiresDefinedPurchaserAuth = loginForm;
  const isWaitingForClaimStatus =
    uid && !previousClaimStatus.hasFetchedClaimStatus;
  const isWaitingForPaymentData =
    previousClaimStatus.claimID && !previousPaymentInfo.hasFetchedPaymentMethod;
  const userHasNoPreviousSubmission =
    previousClaimStatus.hasFetchedClaimStatus &&
    !previousClaimStatus.hasPreviousSubmission;

  const REDIRECTS = [
    //AuthWall
    {
      redirectTo: '/',
      // condition:
      //   caseRequiresDefinedPurchaserAuth &&
      //   !loadingAuthentication &&
      //   !authenticated,
      condition: true,
    },
    {
      redirectTo: '/',
      condition:
        !loadingAuthentication && !authenticated && pathname !== '/claim-form',
    },
    //Submission protection
    // {
    //   redirectTo: '/results',
    //   condition:
    //     previousClaimStatus.hasFetchedClaimStatus &&
    //     previousPaymentInfo.hasFetchedPaymentMethod &&
    //     hasCompletedClaimFlow,
    // },
    // {
    //   redirectTo: '/claim-check',
    //   condition:
    //     previousClaimStatus.hasFetchedClaimStatus &&
    //     previousPaymentInfo.hasFetchedPaymentMethod &&
    //     !hasCompletedClaimFlow &&
    //     previousClaimStatus.hasPreviousSubmission,
    // },
    // //Redirect defined purchasers to the form if no previous claim is present.
    // {
    //   redirectTo: '/claim-form',
    //   condition:
    //     caseRequiresDefinedPurchaserAuth &&
    //     userHasNoPreviousSubmission &&
    //     pathname !== '/claim-instructions',
    // },
    // //Error state: This is only possible if something went wrong during form submission for undefined purchasers (Which takes 2 requests and a signup)
    // {
    //   redirectTo: '/claim-form',
    //   condition:
    //     authenticated && !isDefinedPurchaser && userHasNoPreviousSubmission,
    //   action: async () => {
    //     await signOutFromFirebase();
    //     openSnackbar(
    //       'Sorry, there was an error. Please try submitting your claim again.'
    //     );
    //     navigate('/claim-form');
    //   },
    // },
  ];

  const fetchIsDefinedPurchaser = async () => {
    setLoadingDefinedPurchaserState('pending');
    try {
      const res = await getClaimantData(caseID);
      setIsDefinedPurchaser(res.status === 200);
    } catch (err) {
      setIsDefinedPurchaser(false);
    }
    setLoadingDefinedPurchaserState('success');
  };

  //Data fetching
  useEffect(() => {
    if (!previousClaimStatus.hasFetchedClaimStatus && uid) {
      previousClaimStatus.fetchClaimStatus(caseID, uid);
    }
    if (loadingDefinedPurchaserState === 'idle' && uid) {
      fetchIsDefinedPurchaser();
    }
  }, [
    uid,
    previousClaimStatus.hasFetchedClaimStatus,
    loadingDefinedPurchaserState,
  ]);

  useEffect(() => {
    if (previousClaimStatus.claimID && !previousPaymentInfo.paymentMethod) {
      previousPaymentInfo.fetchPaymentMethod(
        caseID,
        previousClaimStatus.claimID
      );
    }
  }, [previousClaimStatus.claimID]);

  //Redirects
  useEffect(() => {
    if (isInProtectedRoute) {
      REDIRECTS.some((redirect) => {
        if (redirect.condition && pathname !== redirect.redirectTo) {
          redirect.action ? redirect.action() : navigate(redirect.redirectTo);
          return true;
        }
        return false;
      });
    }
  }, [
    uid,
    previousClaimStatus.hasFetchedClaimStatus,
    previousPaymentInfo.hasFetchedPaymentMethod,
    pathname,
    loadingAuthentication,
  ]);

  return children;
};

export default SubmissionProtection;
